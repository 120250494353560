@import "assets/css/normalize.css";
@import "assets/css/material.css";
@import "assets/css/naui.css";
@import "assets/font-awesome/css/all.css";
@import "assets/css/brandbook.css";

main {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

:focus{
    outline:none;
}

.scroll-y {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

.scroll-y::-webkit-scrollbar{
    display: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: "Verdana", "Open Sans", sans-serif; }
