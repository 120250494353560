html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Verdana", "Open Sans", sans-serif;
}

/*
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}*/

/* Fore Colors */
body, a {
    color: #434343;
}

/* Colors */
.blue {
    color: #0b1966;
}

.back-gris {
    background-color: #f0f1f4;
}

/* Typogrpahy */

p {
    line-height: 1.4;
    margin-top: 0;
    color: #6D6E71;
    font-size: 12pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
    color: #004D95;
    margin-top: 0;
    font-weight: 400;
}

h1 {
    font-size: 20pt;
}

h2 {
    font-size: 16pt;
}

h3 {
    font-size: 13pt;
}

.link {
    transition: .3s;
    cursor: pointer;
}

.link:hover, .link:active {
    text-decoration: underline;
}


.btn {
    border: 1px solid #ddd;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10px !important;
    cursor: pointer;
    font-size: 12pt;
}

@media (max-width: 750px) {
    .btn{
        font-size: 11pt;
    }
}


.btn > span, .btn > * > span {
    margin: 0 .25rem;
}

.btn.blue span{
    color: #0b1966;
}

.antialiased{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.naui-select{
    font-size: .9rem;
    color:#555;
    min-width: 150px;
}

article{
    padding-top:2rem;
}

article h2{
    font-size: 1.1rem;
    font-weight: 600;
    color: #707070;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom:1.5rem;
    line-height: 1.5;
}
