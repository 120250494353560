.mat-badge-content {
    font-weight: 600;
    font-size: 12px;
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-badge-small .mat-badge-content {
    font-size: 9px
}

.mat-badge-large .mat-badge-content {
    font-size: 24px
}

.mat-h1, .mat-headline, .mat-typography h1 {
    font: 400 24px/32px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 16px
}

.mat-h2, .mat-title, .mat-typography h2 {
    font: 500 20px/32px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 16px
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
    font: 400 16px/28px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 16px
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
    font: 400 15px/24px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 16px
}

.mat-h5, .mat-typography h5 {
    font: 400 calc(14px * .83)/20px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 12px
}

.mat-h6, .mat-typography h6 {
    font: 400 calc(14px * .67)/20px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 12px
}

.mat-body-2, .mat-body-strong {
    font: 500 14px/24px "Verdana", "Open Sans", sans-serif
}

.mat-body, .mat-body-1, .mat-typography {
    font: 400 14px/20px "Verdana", "Open Sans", sans-serif
}

.mat-body p, .mat-body-1 p, .mat-typography p {
    margin: 0 0 12px
}

.mat-caption, .mat-small {
    font: 400 12px/20px "Verdana", "Open Sans", sans-serif
}

.mat-display-4, .mat-typography .mat-display-4 {
    font: 300 112px/112px "Verdana", "Open Sans", sans-serif;
    letter-spacing: -.05em;
    margin: 0 0 56px
}

.mat-display-3, .mat-typography .mat-display-3 {
    font: 400 56px/56px "Verdana", "Open Sans", sans-serif;
    letter-spacing: -.02em;
    margin: 0 0 64px
}

.mat-display-2, .mat-typography .mat-display-2 {
    font: 400 45px/48px "Verdana", "Open Sans", sans-serif;
    letter-spacing: -.005em;
    margin: 0 0 64px
}

.mat-display-1, .mat-typography .mat-display-1 {
    font: 400 34px/40px "Verdana", "Open Sans", sans-serif;
    margin: 0 0 64px
}

.mat-bottom-sheet-container {
    font: 400 14px/20px "Verdana", "Open Sans", sans-serif
}

.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-button-toggle {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-card {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-card-title {
    font-size: 24px;
    font-weight: 500
}

.mat-card-header .mat-card-title {
    font-size: 20px
}

.mat-card-content, .mat-card-subtitle {
    font-size: 14px
}

.mat-checkbox {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-checkbox-layout .mat-checkbox-label {
    line-height: 24px
}

.mat-chip {
    font-size: 14px;
    font-weight: 500
}

.mat-chip .mat-chip-remove.mat-icon, .mat-chip .mat-chip-trailing-icon.mat-icon {
    font-size: 18px
}

.mat-table {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-header-cell {
    font-size: 12px;
    font-weight: 500
}

.mat-cell, .mat-footer-cell {
    font-size: 14px
}

.mat-calendar {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-calendar-body {
    font-size: 13px
}

.mat-calendar-body-label, .mat-calendar-period-button {
    font-size: 14px;
    font-weight: 500
}

.mat-calendar-table-header th {
    font-size: 11px;
    font-weight: 400
}

.mat-dialog-title {
    font: 500 20px/32px "Verdana", "Open Sans", sans-serif
}

.mat-expansion-panel-header {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400
}

.mat-expansion-panel-content {
    font: 400 14px/20px "Verdana", "Open Sans", sans-serif
}

.mat-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-form-field-wrapper {
    padding-bottom: 1.34375em
}

.mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon {
    font-size: 150%;
    line-height: 1.125
}

.mat-form-field-prefix .mat-icon-button, .mat-form-field-suffix .mat-icon-button {
    height: 1.5em;
    width: 1.5em
}

.mat-form-field-prefix .mat-icon-button .mat-icon, .mat-form-field-suffix .mat-icon-button .mat-icon {
    height: 1.125em;
    line-height: 1.125
}

.mat-form-field-infix {
    padding: .5em 0;
    border-top: .84375em solid transparent
}

.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.34375em) scale(.75);
    width: 133.33333%
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.34374em) scale(.75);
    width: 133.33334%
}

.mat-form-field-label-wrapper {
    top: -.84375em;
    padding-top: .84375em
}

.mat-form-field-label {
    top: 1.34375em
}

.mat-form-field-underline {
    bottom: 1.34375em
}

.mat-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: .66667em;
    top: calc(100% - 1.79167em)
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 1.25em
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: .4375em 0
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
    -ms-transform: translateY(-1.28125em) scale(.75);
    width: 133.33333%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00101px);
    -ms-transform: translateY(-1.28124em) scale(.75);
    width: 133.33334%
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.00102px);
    -ms-transform: translateY(-1.28123em) scale(.75);
    width: 133.33335%
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.28125em
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 1em
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: .54167em;
    top: calc(100% - 1.66667em)
}

@media print {
    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.28122em) scale(.75)
    }

    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.28121em) scale(.75)
    }

    .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.2812em) scale(.75)
    }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding: .25em 0 .75em 0
}

.mat-form-field-appearance-fill .mat-form-field-label {
    top: 1.09375em;
    margin-top: -.5em
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-.59375em) scale(.75);
    width: 133.33333%
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-.59374em) scale(.75);
    width: 133.33334%
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 1em 0
}

.mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.84375em;
    margin-top: -.25em
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.59375em) scale(.75);
    width: 133.33333%
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.59374em) scale(.75);
    width: 133.33334%
}

.mat-grid-tile-footer, .mat-grid-tile-header {
    font-size: 14px
}

.mat-grid-tile-footer .mat-line, .mat-grid-tile-header .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box
}

.mat-grid-tile-footer .mat-line:nth-child(n+2), .mat-grid-tile-header .mat-line:nth-child(n+2) {
    font-size: 12px
}

input.mat-input-element {
    margin-top: -.0625em
}

.mat-menu-item {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 12px
}

.mat-radio-button {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-select {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-select-trigger {
    height: 1.125em
}

.mat-slide-toggle-content {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-slider-thumb-label-text {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 500
}

.mat-stepper-horizontal, .mat-stepper-vertical {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-step-label {
    font-size: 14px;
    font-weight: 400
}

.mat-step-sub-label-error {
    font-weight: 400
}

.mat-step-label-error {
    font-size: 14px
}

.mat-step-label-selected {
    font-size: 14px;
    font-weight: 500
}

.mat-tab-group {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-tab-label, .mat-tab-link {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
    font: 500 20px/32px "Verdana", "Open Sans", sans-serif;
    margin: 0
}

.mat-tooltip {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px
}

.mat-tooltip-handset {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px
}

.mat-list-item {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-list-option {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-list-base .mat-list-item {
    font-size: 16px
}

.mat-list-base .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
    font-size: 14px
}

.mat-list-base .mat-list-option {
    font-size: 16px
}

.mat-list-base .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
    font-size: 14px
}

.mat-list-base .mat-subheader {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-list-base[dense] .mat-list-item {
    font-size: 12px
}

.mat-list-base[dense] .mat-list-item .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
    font-size: 12px
}

.mat-list-base[dense] .mat-list-option {
    font-size: 12px
}

.mat-list-base[dense] .mat-list-option .mat-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    box-sizing: border-box
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
    font-size: 12px
}

.mat-list-base[dense] .mat-subheader {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 500
}

.mat-option {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 16px
}

.mat-optgroup-label {
    font: 500 14px/24px "Verdana", "Open Sans", sans-serif
}

.mat-simple-snackbar {
    font-family: "Verdana", "Open Sans", sans-serif;
    font-size: 14px
}

.mat-simple-snackbar-action {
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500
}

.mat-tree {
    font-family: "Verdana", "Open Sans", sans-serif
}

.mat-nested-tree-node, .mat-tree-node {
    font-weight: 400;
    font-size: 14px
}

.mat-ripple {
    overflow: hidden;
    position: relative
}

.mat-ripple.mat-ripple-unbounded {
    overflow: visible
}

.mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0s cubic-bezier(0, 0, .2, 1);
    transform: scale(0)
}

@media (-ms-high-contrast: active) {
    .mat-ripple-element {
        display: none
    }
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000
}

.cdk-overlay-container:empty {
    display: none
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000
}

.cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%
}

.cdk-overlay-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
    opacity: 0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
}

@media screen and (-ms-high-contrast: active) {
    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        opacity: .6
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0
}

.cdk-overlay-connected-position-bounding-box {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: scroll
}

@keyframes cdk-text-field-autofill-start { /*!*/
}

@keyframes cdk-text-field-autofill-end { /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
    animation-name: cdk-text-field-autofill-start
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation-name: cdk-text-field-autofill-end
}

textarea.cdk-textarea-autosize {
    resize: none
}

textarea.cdk-textarea-autosize-measuring {
    height: auto !important;
    overflow: hidden !important;
    padding: 2px 0 !important;
    box-sizing: content-box !important
}

.mat-ripple-element {
    background-color: rgba(0, 0, 0, .1)
}

.mat-option {
    color: rgba(0, 0, 0, .3)
}

.mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) {
    background: rgba(0, 0, 0, .04)
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: rgba(0, 0, 0, .04)
}

.mat-option.mat-active {
    background: rgba(0, 0, 0, .04);
    color: rgba(0, 0, 0, .3)
}

.mat-option.mat-option-disabled {
    color: rgba(0, 0, 0, .38)
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #0b1966
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #0b1966
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #E28228
}

.mat-optgroup-label {
    color: rgba(0, 0, 0, .54)
}

.mat-optgroup-disabled .mat-optgroup-label {
    color: rgba(0, 0, 0, .38)
}

.mat-pseudo-checkbox {
    color: rgba(0, 0, 0, .54)
}

.mat-pseudo-checkbox::after {
    color: #fafafa
}

.mat-pseudo-checkbox-disabled {
    color: #b0b0b0
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #0b1966
}

.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background: #0b1966
}

.mat-warn .mat-pseudo-checkbox-checked, .mat-warn .mat-pseudo-checkbox-indeterminate {
    background: #E28228
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
    background: #b0b0b0
}

.mat-elevation-z0 {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z1 {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z2 {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z3 {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z4 {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z5 {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z6 {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-elevation-z7 {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12)
}

.mat-elevation-z8 {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z9 {
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12)
}

.mat-elevation-z10 {
    box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z11 {
    box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12)
}

.mat-elevation-z12 {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z13 {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z14 {
    box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12)
}

.mat-elevation-z15 {
    box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z16 {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z17 {
    box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12)
}

.mat-elevation-z18 {
    box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z19 {
    box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12)
}

.mat-elevation-z20 {
    box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z21 {
    box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z22 {
    box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12)
}

.mat-elevation-z23 {
    box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12)
}

.mat-elevation-z24 {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
}

.mat-app-background {
    background-color: #fafafa;
    color: rgba(0, 0, 0, .3)
}

.mat-theme-loaded-marker {
    display: none
}

.mat-autocomplete-panel {
    background: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
    background: #fff
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
    color: rgba(0, 0, 0, .3)
}

.mat-badge-content {
    color: #fff;
    background: #0b1966;
}

@media (-ms-high-contrast: active) {
    .mat-badge-content {
        outline: solid 1px;
        border-radius: 0
    }
}

.mat-badge-accent .mat-badge-content {
    background: #0b1966;
    color: #fff
}

.mat-badge-warn .mat-badge-content {
    color: #fff;
    background: #E28228
}

.mat-badge {
    position: relative
}

.mat-badge-hidden .mat-badge-content {
    display: none
}

.mat-badge-disabled .mat-badge-content {
    background: #b9b9b9;
    color: rgba(0, 0, 0, .38)
}

.mat-badge-content {
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform .2s ease-in-out;
    transform: scale(.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none
}

.mat-badge-content._mat-animation-noopable, .ng-animate-disabled .mat-badge-content {
    transition: none
}

.mat-badge-content.mat-badge-active {
    transform: none
}

.mat-badge-small .mat-badge-content {
    width: 16px;
    height: 16px;
    line-height: 16px
}

.mat-badge-small.mat-badge-above .mat-badge-content {
    top: -8px
}

.mat-badge-small.mat-badge-below .mat-badge-content {
    bottom: -8px
}

.mat-badge-small.mat-badge-before .mat-badge-content {
    left: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
    left: auto;
    right: -16px
}

.mat-badge-small.mat-badge-after .mat-badge-content {
    right: -16px
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
    right: auto;
    left: -16px
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -8px
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -8px
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -8px
}

.mat-badge-medium .mat-badge-content {
    width: 22px;
    height: 22px;
    line-height: 22px
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -11px
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
    bottom: -11px
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
    left: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
    left: auto;
    right: -22px
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
    right: -22px
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
    right: auto;
    left: -22px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -11px
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -11px
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -11px
}

.mat-badge-large .mat-badge-content {
    width: 28px;
    height: 28px;
    line-height: 28px
}

.mat-badge-large.mat-badge-above .mat-badge-content {
    top: -14px
}

.mat-badge-large.mat-badge-below .mat-badge-content {
    bottom: -14px
}

.mat-badge-large.mat-badge-before .mat-badge-content {
    left: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
    left: auto;
    right: -28px
}

.mat-badge-large.mat-badge-after .mat-badge-content {
    right: -28px
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
    right: auto;
    left: -28px
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: auto;
    right: -14px
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -14px
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: auto;
    left: -14px
}

.mat-bottom-sheet-container {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
    background: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-button, .mat-icon-button, .mat-stroked-button {
    color: inherit;
    background: 0 0
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color: #0b1966;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
    color: #0b1966;
}

.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
    color: #E28228
}

.mat-button.mat-accent[disabled], .mat-button.mat-primary[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
    color: rgba(0, 0, 0, .26)
}

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
    background-color: #0b1966;
}

.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
    background-color: #0b1966;
}

.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
    background-color: #E28228
}

.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
    background-color: transparent
}

.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
    opacity: .1;
    background-color: currentColor
}

.mat-button-focus-overlay {
    background: #000
}

.mat-stroked-button:not([disabled]) {
    border-color: rgba(0, 0, 0, .12)
}

.mat-fab, .mat-flat-button, .mat-mini-fab, .mat-raised-button {
    color: rgba(0, 0, 0, .3);
    background-color: #fff
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
    color: #fff
}

.mat-fab.mat-accent, .mat-flat-button.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent {
    color: #fff
}

.mat-fab.mat-warn, .mat-flat-button.mat-warn, .mat-mini-fab.mat-warn, .mat-raised-button.mat-warn {
    color: #fff
}

.mat-fab.mat-accent[disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] {
    color: rgba(0, 0, 0, .26)
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
    background-color: #0b1966;
}

.mat-fab.mat-accent, .mat-flat-button.mat-accent, .mat-mini-fab.mat-accent, .mat-raised-button.mat-accent {
    background-color: #0b1966;
}

.mat-fab.mat-warn, .mat-flat-button.mat-warn, .mat-mini-fab.mat-warn, .mat-raised-button.mat-warn {
    background-color: #E28228
}

.mat-fab.mat-accent[disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled] {
    background-color: rgba(0, 0, 0, .12)
}

.mat-fab.mat-primary .mat-ripple-element, .mat-flat-button.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, .1)
}

.mat-fab.mat-accent .mat-ripple-element, .mat-flat-button.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, .1)
}

.mat-fab.mat-warn .mat-ripple-element, .mat-flat-button.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, .1)
}

.mat-flat-button:not([class*=mat-elevation-z]), .mat-stroked-button:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12)
}

.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12)
}

.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group, .mat-button-toggle-standalone {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard, .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    box-shadow: none
}

.mat-button-toggle {
    color: rgba(0, 0, 0, .38)
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgba(0, 0, 0, .12)
}

.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, .3);
    background: #fff
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
    background-color: #000
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: solid 1px rgba(0, 0, 0, .12)
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
    border-left: none;
    border-right: none;
    border-top: solid 1px rgba(0, 0, 0, .12)
}

.mat-button-toggle-checked {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, .54)
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: rgba(0, 0, 0, .3)
}

.mat-button-toggle-disabled {
    color: rgba(0, 0, 0, .26);
    background-color: #eee
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background: #fff
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
    background-color: #bdbdbd
}

.mat-button-toggle-group-appearance-standard, .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    border: solid 1px rgba(0, 0, 0, .12)
}

.mat-card {
    background: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-card-subtitle {
    color: rgba(0, 0, 0, .54)
}

.mat-checkbox-frame {
    border-color: rgba(0, 0, 0, .54)
}

.mat-checkbox-checkmark {
    fill: #fafafa
}

.mat-checkbox-checkmark-path {
    stroke: #fafafa !important
}

@media (-ms-high-contrast: black-on-white) {
    .mat-checkbox-checkmark-path {
        stroke: #000 !important
    }
}

.mat-checkbox-mixedmark {
    background-color: #fafafa
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
    background-color: #0b1966;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #0b1966;
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background, .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
    background-color: #E28228
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #b0b0b0
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: #b0b0b0
}

.mat-checkbox-disabled .mat-checkbox-label {
    color: rgba(0, 0, 0, .54)
}

@media (-ms-high-contrast: active) {
    .mat-checkbox-disabled {
        opacity: .5
    }
}

@media (-ms-high-contrast: active) {
    .mat-checkbox-background {
        background: 0 0
    }
}

.mat-checkbox .mat-ripple-element {
    background-color: #000
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
    background: #0b1966;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: #0b1966;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
    background: #E28228
}

.mat-chip.mat-standard-chip {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, .3)
}

.mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(0, 0, 0, .3);
    opacity: .4
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12)
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
    opacity: .54
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
    opacity: .4
}

.mat-chip.mat-standard-chip::after {
    background: #000
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background-color: #0b1966;
    color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: #fff;
    opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
    background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
    background-color: #E28228;
    color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
    color: #fff;
    opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
    background: rgba(255, 255, 255, .1)
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
    background-color: #0b1966;
    color: #fff
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    color: #fff;
    opacity: .4
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
    background: rgba(255, 255, 255, .1)
}

.mat-table {
    background: #fff
}

.mat-table tbody, .mat-table tfoot, .mat-table thead, .mat-table-sticky, [mat-footer-row], [mat-header-row], [mat-row], mat-footer-row, mat-header-row, mat-row {
    background: inherit
}

mat-footer-row, mat-header-row, mat-row, td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
    border-bottom-color: rgba(0, 0, 0, .12)
}

.mat-header-cell {
    color: rgba(0, 0, 0, .54)
}

.mat-cell, .mat-footer-cell {
    color: rgba(0, 0, 0, .3)
}

.mat-calendar-arrow {
    border-top-color: rgba(0, 0, 0, .54)
}

.mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-toggle {
    color: rgba(0, 0, 0, .54)
}

.mat-calendar-table-header {
    color: rgba(0, 0, 0, .38)
}

.mat-calendar-table-header-divider::after {
    background: rgba(0, 0, 0, .12)
}

.mat-calendar-body-label {
    color: rgba(0, 0, 0, .54)
}

.mat-calendar-body-cell-content {
    color: rgba(0, 0, 0, .3);
    border-color: transparent
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: rgba(0, 0, 0, .38)
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected), .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: rgba(0, 0, 0, .04)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, .38)
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: rgba(0, 0, 0, .18)
}

.mat-calendar-body-selected {
    background-color: #0b1966;
    color: #fff
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(63, 81, 181, .4)
}

.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    background-color: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: #0b1966;
    color: #fff
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(255, 64, 129, .4)
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
    background-color: #E28228;
    color: #fff
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: rgba(244, 67, 54, .4)
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px #fff
}

.mat-datepicker-content-touch {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .2), 0 0 0 0 rgba(0, 0, 0, .14), 0 0 0 0 rgba(0, 0, 0, .12)
}

.mat-datepicker-toggle-active {
    color: #0b1966
}

.mat-datepicker-toggle-active.mat-accent {
    color: #0b1966
}

.mat-datepicker-toggle-active.mat-warn {
    color: #E28228
}

.mat-dialog-container {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    background: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-divider {
    border-top-color: rgba(0, 0, 0, .12)
}

.mat-divider-vertical {
    border-right-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel {
    background: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.mat-action-row {
    border-top-color: rgba(0, 0, 0, .12)
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
    background: rgba(0, 0, 0, .04)
}

@media (hover: none) {
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
        background: #fff
    }
}

.mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, .3)
}

.mat-expansion-indicator::after, .mat-expansion-panel-header-description {
    color: rgba(0, 0, 0, .54)
}

.mat-expansion-panel-header[aria-disabled=true] {
    color: rgba(0, 0, 0, .26)
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description, .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title {
    color: inherit
}

.mat-form-field-label {
    color: rgba(0, 0, 0, .6)
}

.mat-hint {
    color: rgba(0, 0, 0, .6)
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #0b1966
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
    color: #0b1966
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
    color: #E28228
}

.mat-focused .mat-form-field-required-marker {
    color: #0b1966
}

.mat-form-field-ripple {
    background-color: rgba(0, 0, 0, .3)
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #0b1966
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: #0b1966
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
    background-color: #E28228
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
    color: #0b1966
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
    color: #0b1966
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
    color: #E28228
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #E28228
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker, .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
    color: #E28228
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: #E28228
}

.mat-error {
    color: #E28228
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(0, 0, 0, .54)
}

.mat-form-field-appearance-legacy .mat-hint {
    color: rgba(0, 0, 0, .54)
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #b4b4b4;
    margin-top: .5rem;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
    background-size: 4px 100%;
    background-repeat: repeat-x
}

.mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
    background-size: 4px 100%;
    background-repeat: repeat-x
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(0, 0, 0, .04)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(0, 0, 0, .02)
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba(0, 0, 0, .42)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
    background-color: transparent
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, .12)
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, .3)
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #0b1966
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
    color: #0b1966
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
    color: #E28228
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #E28228
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, .38)
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: rgba(0, 0, 0, .06)
}

.mat-icon.mat-primary {
    color: #0b1966
}

.mat-icon.mat-accent {
    color: #0b1966
}

.mat-icon.mat-warn {
    color: #E28228
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: rgba(0, 0, 0, .54)
}

.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after, .mat-input-element:disabled {
    color: rgba(0, 0, 0, .38)
}

.mat-input-element {
    caret-color: #0b1966
}

.mat-input-element::placeholder {
    color: rgba(0, 0, 0, .42)
}

.mat-input-element::-moz-placeholder {
    color: rgba(0, 0, 0, .42)
}

.mat-input-element::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .42)
}

.mat-input-element:-ms-input-placeholder {
    color: rgba(0, 0, 0, .42)
}

.mat-accent .mat-input-element {
    caret-color: #0b1966
}

.mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
    caret-color: #E28228
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
    color: #E28228
}

.mat-list-base .mat-list-item {
    color: rgba(0, 0, 0, .3)
}

.mat-list-base .mat-list-option {
    color: rgba(0, 0, 0, .3)
}

.mat-list-base .mat-subheader {
    color: rgba(0, 0, 0, .54)
}

.mat-list-item-disabled {
    background-color: #eee
}

.mat-action-list .mat-list-item:focus, .mat-action-list .mat-list-item:hover, .mat-list-option:focus, .mat-list-option:hover, .mat-nav-list .mat-list-item:focus, .mat-nav-list .mat-list-item:hover {
    background: rgba(0, 0, 0, .04)
}

.mat-menu-panel {
    background: #fff
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-menu-item {
    background: 0 0;
    color: rgba(0, 0, 0, .3)
}

.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
    color: rgba(0, 0, 0, .38)
}

.mat-menu-item .mat-icon-no-color, .mat-menu-item-submenu-trigger::after {
    color: rgba(0, 0, 0, .54)
}

.mat-menu-item-highlighted:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item:hover:not([disabled]) {
    background: rgba(0, 0, 0, .04)
}

.mat-paginator {
    background: #fff
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
    color: rgba(0, 0, 0, .54)
}

.mat-paginator-decrement, .mat-paginator-increment {
    border-top: 2px solid rgba(0, 0, 0, .54);
    border-right: 2px solid rgba(0, 0, 0, .54)
}

.mat-paginator-first, .mat-paginator-last {
    border-top: 2px solid rgba(0, 0, 0, .54)
}

.mat-icon-button[disabled] .mat-paginator-decrement, .mat-icon-button[disabled] .mat-paginator-first, .mat-icon-button[disabled] .mat-paginator-increment, .mat-icon-button[disabled] .mat-paginator-last {
    border-color: rgba(0, 0, 0, .38)
}

.mat-progress-bar-background {
    fill: #c5cae9
}

.mat-progress-bar-buffer {
    background-color: #c5cae9
}

.mat-progress-bar-fill::after {
    background-color: #0b1966
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
    fill: #ff80ab
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
    background-color: #ff80ab
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
    background-color: #0b1966
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
    fill: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
    background-color: #ffcdd2
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
    background-color: #E28228
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #0b1966
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
    stroke: #0b1966
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
    stroke: #E28228
}

.mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, .54)
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0b1966
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: #0b1966
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0b1966
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #0b1966
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
    border-color: #E28228
}

.mat-radio-button.mat-warn .mat-radio-inner-circle, .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
    background-color: #E28228
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
    border-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle, .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element {
    background-color: rgba(0, 0, 0, .38)
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: rgba(0, 0, 0, .38)
}

.mat-radio-button .mat-ripple-element {
    background-color: #000
}

.mat-select-value {
    color: rgba(0, 0, 0, .3)
}

.mat-select-placeholder {
    color: rgba(0, 0, 0, .42)
}

.mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, .38)
}

.mat-select-arrow {
    color: rgba(0, 0, 0, .54)
}

.mat-select-panel {
    background: #fff
}

.mat-select-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: rgba(0, 0, 0, .12)
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: #0b1966
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
    color: #0b1966
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
    color: #E28228
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
    color: #E28228
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
    color: rgba(0, 0, 0, .38)
}

.mat-drawer-container {
    background-color: #fafafa;
    color: rgba(0, 0, 0, .3)
}

.mat-drawer {
    background-color: #fff;
    color: rgba(0, 0, 0, .3)
}

.mat-drawer.mat-drawer-push {
    background-color: #fff
}

.mat-drawer:not(.mat-drawer-side) {
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12)
}

.mat-drawer-side {
    border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-drawer-side.mat-drawer-end {
    border-left: solid 1px rgba(0, 0, 0, .12);
    border-right: none
}

[dir=rtl] .mat-drawer-side {
    border-left: solid 1px rgba(0, 0, 0, .12);
    border-right: none
}

[dir=rtl] .mat-drawer-side.mat-drawer-end {
    border-left: none;
    border-right: solid 1px rgba(0, 0, 0, .12)
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, .6)
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #0b1966
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(255, 64, 129, .54)
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: #0b1966
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: #0b1966
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(63, 81, 181, .54)
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
    background-color: #0b1966
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
    background-color: #E28228
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(244, 67, 54, .54)
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
    background-color: #E28228
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
    background-color: #000
}

.mat-slide-toggle-thumb {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    background-color: #fafafa
}

.mat-slide-toggle-bar {
    background-color: rgba(0, 0, 0, .38)
}

.mat-slider-track-background {
    background-color: rgba(0, 0, 0, .26)
}

.mat-primary .mat-slider-thumb, .mat-primary .mat-slider-thumb-label, .mat-primary .mat-slider-track-fill {
    background-color: #0b1966
}

.mat-primary .mat-slider-thumb-label-text {
    color: #fff
}

.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill {
    background-color: #0b1966
}

.mat-accent .mat-slider-thumb-label-text {
    color: #fff
}

.mat-warn .mat-slider-thumb, .mat-warn .mat-slider-thumb-label, .mat-warn .mat-slider-track-fill {
    background-color: #E28228
}

.mat-warn .mat-slider-thumb-label-text {
    color: #fff
}

.mat-slider-focus-ring {
    background-color: rgba(255, 64, 129, .2)
}

.cdk-focused .mat-slider-track-background, .mat-slider:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, .38)
}

.mat-slider-disabled .mat-slider-thumb, .mat-slider-disabled .mat-slider-track-background, .mat-slider-disabled .mat-slider-track-fill {
    background-color: rgba(0, 0, 0, .26)
}

.mat-slider-disabled:hover .mat-slider-track-background {
    background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value .mat-slider-focus-ring {
    background-color: rgba(0, 0, 0, .12)
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, .3)
}

.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
    background-color: rgba(0, 0, 0, .26)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    border-color: rgba(0, 0, 0, .26);
    background-color: transparent
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
    border-color: rgba(0, 0, 0, .38)
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb {
    border-color: rgba(0, 0, 0, .26)
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
    border-color: rgba(0, 0, 0, .7)
}

.mat-slider-horizontal .mat-slider-ticks {
    background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent);
    background-image: -moz-repeating-linear-gradient(.0001deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-slider-vertical .mat-slider-ticks {
    background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7) 2px, transparent 0, transparent)
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background-color: rgba(0, 0, 0, .04)
}

@media (hover: none) {
    .mat-step-header:hover {
        background: 0 0
    }
}

.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
    color: rgba(0, 0, 0, .54)
}

.mat-step-header .mat-step-icon {
    background-color: rgba(0, 0, 0, .54);
    color: #fff
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #0b1966;
    color: #fff
}

.mat-step-header .mat-step-icon-state-error {
    background-color: transparent;
    color: #E28228
}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: rgba(0, 0, 0, .3)
}

.mat-step-header .mat-step-label.mat-step-label-error {
    color: #E28228
}

.mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: #fff
}

.mat-stepper-vertical-line::before {
    border-left-color: rgba(0, 0, 0, .12)
}

.mat-horizontal-stepper-header::after, .mat-horizontal-stepper-header::before, .mat-stepper-horizontal-line {
    border-top-color: rgba(0, 0, 0, .12)
}

.mat-sort-header-arrow {
    color: #757575
}

.mat-tab-header, .mat-tab-nav-bar {
    border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.mat-tab-group-inverted-header .mat-tab-header, .mat-tab-group-inverted-header .mat-tab-nav-bar {
    border-top: 1px solid rgba(0, 0, 0, .12);
    border-bottom: none
}

.mat-tab-label, .mat-tab-link {
    color: #636466;
    opacity: 1 !important;
}

.mat-tab-label-active{
    color: #0b1966;
}

.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
    color: rgba(0, 0, 0, .38)
}

.mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, .3)
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, .38)
}

.mat-tab-group[class*=mat-background-] .mat-tab-header, .mat-tab-nav-bar[class*=mat-background-] {
    border-bottom: none;
    border-top: none
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(197, 202, 233, .3)
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #0b1966
}

.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
    background-color: #fff
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 128, 171, .3)
}

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
    background-color: #0b1966
}

.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
    background-color: #fff
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
    background-color: #E28228
}

.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
    background-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(197, 202, 233, .3)
}

.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-links {
    background-color: #0b1966
}

.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
    color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
    border-color: #fff
}

.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
    background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 128, 171, .3)
}

.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-links {
    background-color: #0b1966
}

.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
    color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
    border-color: #fff
}

.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
    background-color: rgba(255, 255, 255, .12)
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
    background-color: rgba(255, 205, 210, .3)
}

.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-links {
    background-color: #E28228
}

.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
    color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
    color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
    border-color: #fff
}

.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(255, 255, 255, .4)
}

.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
    background-color: rgba(255, 255, 255, .12)
}

.mat-toolbar {
    background: #f5f5f5;
    color: rgba(0, 0, 0, .3)
}

.mat-toolbar.mat-primary {
    background: #0b1966;
    color: #fff
}

.mat-toolbar.mat-accent {
    background: #0b1966;
    color: #fff
}

.mat-toolbar.mat-warn {
    background: #E28228;
    color: #fff
}

.mat-toolbar .mat-focused .mat-form-field-ripple, .mat-toolbar .mat-form-field-ripple, .mat-toolbar .mat-form-field-underline {
    background-color: currentColor
}

.mat-toolbar .mat-focused .mat-form-field-label, .mat-toolbar .mat-form-field-label, .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow, .mat-toolbar .mat-select-arrow, .mat-toolbar .mat-select-value {
    color: inherit
}

.mat-toolbar .mat-input-element {
    caret-color: currentColor
}

.mat-tooltip {
    background: rgba(97, 97, 97, .9)
}

.mat-tree {
    background: #fff
}

.mat-nested-tree-node, .mat-tree-node {
    color: rgba(0, 0, 0, .3)
}

.mat-snack-bar-container {
    color: rgba(255, 255, 255, .7);
    background: #323232;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12)
}

.mat-simple-snackbar-action {
    color: #0b1966
}

@media(max-width: 750px){
    .mat-tab-label, .mat-tab-link{
        font-size: 13px;
    }

    .mat-checkbox-layout{
        white-space: normal !important;
    }

    .mat-checkbox-layout .mat-checkbox-label{
        line-height: 1.4rem !important;
        margin-left: .25rem;
    }
}


.filtros .mat-form-field-infix{
    width: 150px;
}
