/* Fix */
*, a, a:hover{
    text-decoration:none;
    box-sizing: border-box;
}

/* Text Direction */
.text-left{
    text-align:left;
}

.text-right{
    text-align:right;
}

.text-center{
    text-align:center;
}

/* Layout */
.container-fluid{
    padding-left:1rem;
    padding-right:1rem;
}

.container-fluid.regular{
    padding-left: 3vw;
    padding-right: 3vw;
}

@media(max-width:750px){
    .container-fluid{
        width:100%;
        margin:0;
        padding-left:1rem;
        padding-right:1rem;
    }
}

.w-100{
    width:100%;
}

.h-100{
    height:100%;
}

img{
    display: block;
}

/* Widths */
.w300{
    max-width:300px;
}

.w400{
    max-width:400px;
}

.w500{
    max-width:500px;
}

.w600{
    max-width:600px;
}

.w700{
    max-width:700px;
}

.w800{
    max-width:800px;
}

.w900{
    max-width:900px;
}

.w1000{
    max-width:1000px;
}

.w1100{
    max-width:1100px;
}

.w1200{
    max-width:1200px;
}

/* Grid Utilities */
.rgrid{
    display:grid;
    width:100%;
}

.grid2{
    display:grid;
    grid-template-columns: 1fr 1fr;
    width:100%;
}

.grid3{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    width:100%;
}

.grid4{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width:100%;
}

.grid57{
    display:grid;
    grid-template-columns: 5fr 7fr;
    width:100%;
}

.grid75{
    display:grid;
    grid-template-columns: 7fr 5fr;
    width:100%;
}

.grid12{
    display:grid;
    grid-template-columns: 1fr 2fr;
    width:100%;
}

.grid21{
    display:grid;
    grid-template-columns: 2fr 1fr;
    width:100%;
}

.grid31{
    display:grid;
    grid-template-columns: 3fr 1fr;
    width:100%;
}

.grid13{
    display:grid;
    grid-template-columns: 1fr 3fr;
    width:100%;
}

/* Gap */
.gap1{
    grid-gap: 1rem;
}

.gap15{
    grid-gap: 1.5rem;
}

.gap2{
   grid-gap: 2rem;
}

.gap10{
    grid-gap:1rem;
}

.gap15{
    grid-gap:1.5rem;
}

.gap20{
    grid-gap:2rem;
}

.gap25{
    grid-gap:2.5rem;
}

.gap30{
    grid-gap:3rem;
}

@media(max-width: 900px){
    .rgrid{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:750px){
    .rgrid{
        grid-template-columns: 100%;
    }

    .rgrid{
        grid-gap:1rem;
    }
}

/* Utilities */
.m-auto{
    margin: 0 auto;
}

.pad, .block{
    padding:1rem;
}

.pad2{
    padding:2rem;
}

.pad3{
    padding:3rem;
}

.pad10{
    padding:1rem 0;
}

.pad20{
    padding:2rem 0;
}

.pad30{
    padding:3rem 0;
}

.pad01{
    padding:0 1rem;
}

.pad02{
    padding: 0 2rem;
}

.pad03{
    padding:0 3rem;
}

.pad12{
    padding:1rem 2rem;
}

.pad-top-1{
    padding-top:1rem;
}

.pad-top-2{
    padding-top: 2rem;
}

/* Box Utils */
.box-center{
    display:flex;
    justify-content: center;
    align-self: center;
}

.box-center>*{
    align-self: center;
}

.box-left{
    display:flex;
    justify-content: flex-start;
    align-self: center;
}

.box-left>*{
    align-self: center;
}

.box-right{
    display:flex;
    justify-content: flex-end;
    align-self: center;
}

.box-right>*{
    align-self: center;
}

.extended{
    justify-content: space-between;
}

/* Elements */
.naui-overlay-container{
    position:relative;
}

.naui-overlay{
    position:absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
}

/* Style */
.naui-shadow{
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
}

dd{
    margin-left:0;
}

.spaced>*{
    margin-right:.5rem;
}

.spaced>*:last-child, .double-spaced>*:last-child{
    margin-right: 0;
}

.double-spaced>*{
    margin-right: 1rem;
}

.pointer{
    cursor: pointer;
}

/* Forms */
.naui-input, .naui-select{
    height:40px;
    border-radius:4px;
    border:1px solid #ddd;
    padding-left:.5rem;
}

.naui-input:focus, .naui-select:focus{
    outline: none;
    border-color: #0b1966;
}

.form-group{
    margin-bottom: 1rem;
}

fieldset{
    padding:0;
    border: none;
}
